import betanoLogo from "../assets/images/sportsbooks/betano.png";
import happybetLogo from "../assets/images/sportsbooks/happybet.jpg";
import merkurbetsLogo from "../assets/images/sportsbooks/merkurbets.jpg";
import merkurLogo from "../assets/images/casinos/merkur.jpg";

import visaLogo from "../assets/images/payment/WW VISA.svg";
import mastercardLogo from "../assets/images/payment/WW Mastercard.svg";
import paypalLogo from "../assets/images/payment/WW PayPal.svg";
import skrillLogo from "../assets/images/payment/EU Skrill.svg";
import paysafecardLogo from "../assets/images/payment/WW paysafecard.svg";
import sofortLogo from "../assets/images/payment/EU Klarna Sofort.svg";
import bankLogo from "../assets/images/payment/DE U╠êberweisung.svg";

export const sportsbettingProvidersData = {
  betano: {
    name: "Betano",
    slug: "betano",
    title: "Betano Sportwetten Erfahrungen & Test 2024 | Ist Betano seriös?",
    metaDescription:
      "Ausführlicher Betano Sportwetten Test mit echten Erfahrungen ✓ 100% Bonus + 20€ Freiwette ✓ Wettquoten ✓ Wettangebot ✓ Jetzt informieren und profitieren!",
    ctaLink: "https://www.betano.com/de/",
    logo: betanoLogo,
    highlights: [
      "100% Einzahlungsbonus bis zu 80€",
      "20€ Gratiswette ohne Einzahlung",
      "Riesiges Livewetten-Angebot",
      "Deutsche Lizenz und Sicherheit",
    ],
    bonus: {
      type: "100% Einzahlungsbonus + 20€ Freiwette",
      maxBonus: "100€ (80€ Einzahlungsbonus + 20€ Freiwette)",
      minDeposit: "10€",
      maxDeposit: "80€",
      wageringRequirements: "5x Bonus + Einzahlung",
      oddsRequirement:
        "1.65 (für Bonusguthaben) - keine Mindestquote für die Freiwette",
      timeframe: "90 Tage (ab Gutschrift)",
      validity: "1x pro Haushalt",
      availableIn: ["Deutschland"],
    },
    ratings: [
      { category: "Bonusangebote & Promotionen", score: 5 },
      { category: "Wettquoten", score: 4 },
      { category: "Sportarten- & Wettangebot", score: 5 },
      { category: "Zahlungsmethoden", score: 5 },
      { category: "Kundensupport", score: 4 },
      { category: "Wettsteuer & Gebühren", score: 1 },
      { category: "Mobile App & Optimierung", score: 5 },
      { category: "Treueprogramme & VIP-Services", score: 4 },
      { category: "Cash-Out-Funktion", score: 5 },
    ],
    faqs: [
      {
        question: "Ist Betano seriös und sicher?",
        answer:
          "Ja, Betano ist ein seriöser Anbieter. Der Buchmacher verfügt über eine deutsche Lizenz und unterliegt somit den strengen Auflagen der deutschen Glücksspielbehörde. Zudem setzt Betano auf moderne Sicherheitsstandards und verschlüsselt sämtliche Datenübertragungen.",
      },
      {
        question: "Gibt es einen Betano Bonus für Neukunden?",
        answer:
          "Ja, Betano bietet neuen Kunden einen attraktiven Willkommensbonus. Der Buchmacher verdoppelt die erste Einzahlung bis zu einem Betrag von 80€. Zusätzlich erhalten Neukunden eine 20€ Gratiswette ohne Einzahlung.",
      },
      {
        question: "Welche Sportarten bietet Betano an?",
        answer:
          "Betano überzeugt mit einem breiten Wettangebot und bietet zahlreiche Sportarten an. Neben Fußball, Tennis und Basketball können Kunden auch auf Randsportarten wie Tischtennis, Darts oder eSports wetten.",
      },
      {
        question: "Kann ich bei Betano auch mobil wetten?",
        answer:
          "Ja, Betano verfügt über eine benutzerfreundliche App, die es Kunden ermöglicht, auch unterwegs zu wetten. Die App ist sowohl für iOS als auch für Android verfügbar und bietet alle Funktionen der Desktop-Version.",
      },
      {
        question: "Wie erreiche ich den Betano Kundensupport?",
        answer:
          "Der Betano Kundensupport steht Kunden per Live-Chat, E-Mail und Telefon zur Verfügung. Der Live-Chat ist die schnellste und einfachste Möglichkeit, um Hilfe zu erhalten. Alternativ können Kunden auch eine E-Mail schreiben.",
      },
      {
        question: "Muss ich bei Betano Wettsteuer zahlen?",
        answer:
          "Ja, bei Betano fällt die deutsche Wettsteuer in Höhe von 5% an. Diese wird bei jeder platzierten Wette automatisch vom Einsatz abgezogen. Kunden sollten dies bei der Wettabgabe berücksichtigen.",
      },
      {
        question: "Bietet Betano eine Cash-Out-Funktion an?",
        answer:
          "Ja, Betano bietet eine Cash-Out-Funktion an, mit der Kunden ihre Wetten vorzeitig auswerten können. Dadurch haben Kunden die Möglichkeit, ihre Gewinne zu sichern oder Verluste zu minimieren, bevor das Ereignis beendet ist.",
      },
    ],
    paymentMethods: [
      { name: "Visa", logo: visaLogo },
      { name: "Mastercard", logo: mastercardLogo },
      { name: "PayPal", logo: paypalLogo },
      { name: "Skrill", logo: skrillLogo },
      {
        name: "Paysafecard",
        logo: paysafecardLogo,
      },
      {
        name: "Sofortüberweisung",
        logo: sofortLogo,
      },
      { name: "Banküberweisung", logo: bankLogo },
    ],
    overview: {
      foundingYear: "2016",
      licence:
        "Malta Gaming Authority (MGA), zusätzlich deutsche Lizenz gemäß dem neuen Glücksspielstaatsvertrag",
      specialFeatures: [
        "Umfangreiches Live-Wetten-Angebot",
        "Innovative Funktionen wie Bet Mentor und Match Combo",
        "Cash-Out-Funktion",
        "Mobile App für iOS und Android",
      ],
      website: "www.betano.com",
      security: ["SSL-Verschlüsselung", "Responsible Gaming Maßnahmen"],
      description:
        "Betano gehört zur Kaizen Gaming International Ltd., einem Unternehmen mit langjähriger Erfahrung in der iGaming-Branche. Der Anbieter legt großen Wert auf Transparenz und Spielerschutz, was sich in seinen umfangreichen Maßnahmen zur Förderung von verantwortungsbewusstem Spielen widerspiegelt.",
    },
    pros: [
      "Attraktiver Willkommensbonus mit 100% bis zu 80€ und 20€ Freiwette ohne Einzahlung",
      "Breites Wettangebot mit vielfältigen Sportarten und Spezialwetten",
      "Benutzerfreundliche Mobile App für iOS und Android",
      "Sichere und schnelle Auszahlungen ohne Gebühren",
      "Innovative Funktionen wie Bet Mentor und Match Combo",
      "Umfangreiches Treueprogramm für Stammkunden",
      "Flexible Cash-Out-Optionen für mehr Kontrolle",
    ],
    cons: [
      "Wettsteuer von 5% wird auf Gewinne umgelegt",
      "Telefonischer Kundensupport nicht rund um die Uhr verfügbar",
      "Auswahl bei Auszahlungsoptionen könnte größer sein",
    ],
  },
  // happybet: {
  //   name: "Happybet",
  //   slug: "happybet",
  //   title: "Happybet Erfahrungen & Test 2024 | Ist Happybet seriös?",
  //   metaDescription:
  //     "Ausführlicher Happybet Test mit echten Erfahrungen ✓ 150% Bonus bis 100€ ✓ Steuerfreie Wetten ✓ Jetzt informieren und profitieren!",
  //   ctaLink: "https://www.hpybet.com/",
  //   logo: happybetLogo,
  //   highlights: [
  //     "150% Einzahlungsbonus bis zu 100€ – zahle 67€ ein und wette mit 167€",
  //     "Steuerfreie Wetten ab 2er Kombis, mehr Gewinn ohne zusätzliche Kosten",
  //     "Exklusive VIP-Kundenbetreuung für persönlichen Service und exklusive Angebote",
  //     "Breites Wettangebot mit vielen Sportarten und Spezialwetten",
  //     "Sichere und schnelle Ein- und Auszahlungen ohne Gebühren",
  //     "Gutes Treueprogramm mit attraktiven Prämien",
  //   ],
  //   bonus: {
  //     type: "150% Einzahlungsbonus",
  //     maxBonus: "100€",
  //     minDeposit: "10€",
  //     maxDeposit: "67€",
  //     wageringRequirements: "1x Einzahung + 6x Bonus",
  //     oddsRequirement: "2.00",
  //     timeframe: "90 Tage (ab Gutschrift)",
  //     validity: "1x pro Haushalt",
  //     availableIn: ["Deutschland", "Österreich"],
  //   },
  //   ratings: [
  //     { category: "Bonusangebote & Promotionen", score: 5 },
  //     { category: "Wettquoten", score: 4 },
  //     { category: "Sportarten- & Wettangebot", score: 4 },
  //     { category: "Zahlungsmethoden", score: 5 },
  //     { category: "Kundensupport", score: 4 },
  //     { category: "Wettsteuer & Gebühren", score: 4 },
  //     { category: "Mobile App & Optimierung", score: 4 },
  //     { category: "Treueprogramme & VIP-Services", score: 4 },
  //     { category: "Cash-Out-Funktion", score: 3 },
  //   ],
  //   faqs: [
  //     {
  //       question: "Ist Happybet ein seriöser Anbieter?",
  //       answer:
  //         "Ja, Happybet verfügt über Lizenzen der Malta Gaming Authority und der deutschen Glücksspielbehörde. Der Anbieter legt großen Wert auf Sicherheit und verantwortungsbewusstes Spielen.",
  //     },
  //     {
  //       question: "Wie erhalte ich den Happybet Willkommensbonus?",
  //       answer:
  //         "Registriere dich auf der Happybet-Webseite und tätige eine Mindesteinzahlung von 10€. Bei einer Einzahlung von 67€ erhältst du den maximalen Bonus von 100€ und startest mit insgesamt 167€ Wettguthaben.",
  //     },
  //     {
  //       question: "Muss ich die Wettsteuer bei Happybet zahlen?",
  //       answer:
  //         "Bei Einzelwetten wird die gesetzliche Wettsteuer von 5% auf den Kunden umgelegt. Ab 2er Kombiwetten entfällt die Wettsteuer, sodass du deine Gewinne ohne Abzüge erhältst.",
  //     },
  //     {
  //       question: "Bietet Happybet eine mobile App an?",
  //       answer:
  //         "Ja, Happybet bietet eine App für iOS und Android. Die App ermöglicht es dir, auch unterwegs Wetten zu platzieren und dein Konto zu verwalten.",
  //     },
  //     {
  //       question: "Wie kann ich den Kundensupport erreichen?",
  //       answer:
  //         "Der Kundensupport ist per Live-Chat, E-Mail und Telefon erreichbar. Beachte jedoch, dass der Support nicht rund um die Uhr verfügbar ist.",
  //     },
  //     {
  //       question: "Welche Zahlungsmethoden stehen zur Verfügung?",
  //       answer:
  //         "Du kannst Einzahlungen über Kreditkarten, E-Wallets wie PayPal, Banküberweisungen und Prepaid-Karten tätigen. Auszahlungen erfolgen über die gleichen Methoden.",
  //     },
  //     {
  //       question: "Gibt es ein Treueprogramm bei Happybet?",
  //       answer:
  //         "Ja, durch regelmäßiges Wetten sammelst du Punkte, die du gegen Boni oder Gratiswetten eintauschen kannst. Als VIP-Kunde profitierst du von zusätzlichen Vorteilen.",
  //     },
  //     {
  //       question: "Wie funktioniert die Cash-Out-Funktion?",
  //       answer:
  //         "Bei ausgewählten Wetten kannst du die Cash-Out-Option nutzen, um deine Wette vorzeitig auszuwerten. Teilweiser oder automatischer Cash-Out sind allerdings nicht verfügbar.",
  //     },
  //   ],
  //   paymentMethods: [
  //     { name: "Visa", logo: visaLogo },
  //     { name: "Mastercard", logo: mastercardLogo },
  //     { name: "PayPal", logo: paypalLogo },
  //     { name: "Skrill", logo: skrillLogo },
  //     {
  //       name: "Paysafecard",
  //       logo: paysafecardLogo,
  //     },
  //     {
  //       name: "Sofortüberweisung",
  //       logo: sofortLogo,
  //     },
  //     { name: "Banküberweisung", logo: bankLogo },
  //   ],
  //   overview: {
  //     foundingYear: "2017",
  //     licence:
  //       "Malta Gaming Authority (MGA), deutsche Lizenz gemäß Glücksspielstaatsvertrag",
  //     specialFeatures: [
  //       "Steuerfreie Wetten ab 2er Kombis",
  //       "Exklusive VIP-Kundenbetreuung",
  //       "Cash-Out-Funktion",
  //       "Mobile App für iOS und Android",
  //     ],
  //     website: "www.hpybet.com",
  //     security: [
  //       "SSL-Verschlüsselung",
  //       "Maßnahmen zum verantwortungsbewussten Spielen",
  //     ],
  //   },
  //   pros: [
  //     "Attraktiver Willkommensbonus mit 150% bis zu 100€",
  //     "Steuerfreie Wetten ab 2er Kombis, mehr Gewinn für dich",
  //     "Exklusive VIP-Kundenbetreuung mit persönlichen Services",
  //     "Breites Wettangebot mit vielen Sportarten und Spezialwetten",
  //     "Sichere und schnelle Ein- und Auszahlungen ohne Gebühren",
  //     "Gutes Treueprogramm für Stammkunden",
  //   ],
  //   cons: [
  //     "Wettsteuer wird bei Einzelwetten auf den Kunden umgelegt",
  //     "Cash-Out-Funktion bietet weniger Flexibilität",
  //     "Kundensupport nicht rund um die Uhr verfügbar",
  //     "Mobile App könnte in Design und Performance verbessert werden",
  //   ],
  // },
  merkurbets: {
    name: "Merkur Bets",
    slug: "merkurbets",
    title: "Merkur Bets Erfahrungen & Test 2024 | Ist Merkur Bets seriös?",
    metaDescription:
      "Ausführlicher Merkur Bets Test mit echten Erfahrungen ✓ 100% 3er-Kombi Cashback + 10€ Gratiswette ✓ Wettsteuer übernommen ✓ Jetzt informieren und profitieren!",
    ctaLink: "https://media.cashpointpartners.com/C.ashx?btag=a_8433b_2360c_&affid=4834&siteid=8433&adid=2360&c=",
    logo: merkurbetsLogo,
    highlights: [
      "10€ Freiwette ohne Einzahlung, sofort nach Registrierung verfügbar",
      "Wettsteuer wird vom Anbieter übernommen, volle Gewinne ohne Abzüge",
      "100% 3er-Kombi Cashback bis zu 90€ und 10€ Gratiswette",
      "Quickbet: Blitzschnelle Wetten ideal für Livewetten",
      "Kombi Boost: Bis zu 30% zusätzlichen Gewinn bei Kombiwetten",
      "Bet Builder: Erstelle individuelle Wetten nach deinen Vorlieben",
      "Benutzerfreundliche Mobile App für iOS und Android",
      "Sichere und schnelle Ein- und Auszahlungen ohne Gebühren",
      "3er-Kombi-Versicherung: Bis zu 90€ Cashback bei verlorenen Kombiwetten",
    ],
    bonus: {
      /* TODO: Update bonus details */
      type: "10€ Freiwette ohne Einzahlung + 90€ Cashback als Freiwette",
      maxBonus: "100€",
      minDeposit: "10€",
      maxDeposit: "67€",
      wageringRequirements: "1x Einzahung + 6x Bonus",
      oddsRequirement: "2.00",
      timeframe: "90 Tage (ab Gutschrift)",
      validity: "1x pro Haushalt",
      availableIn: ["Deutschland"],
    },
    ratings: [
      { category: "Bonusangebote & Promotionen", score: 5 },
      { category: "Wettquoten", score: 4 },
      { category: "Sportarten- & Wettangebot", score: 4 },
      { category: "Zahlungsmethoden", score: 5 },
      { category: "Kundensupport", score: 4 },
      { category: "Wettsteuer & Gebühren", score: 5 },
      { category: "Mobile App & Optimierung", score: 5 },
      { category: "Treueprogramme & VIP-Services", score: 4 },
      { category: "Cash-Out-Funktion", score: 5 },
    ],
    faqs: [
      {
        question: "Ist Merkur Bets ein seriöser Anbieter?",
        answer:
          "Ja, Merkur Bets ist Teil der Gauselmann Gruppe, einem etablierten deutschen Unternehmen mit langer Tradition in der Glücksspielbranche. Der Anbieter verfügt über eine deutsche Sportwetten-Lizenz und setzt auf hohe Sicherheitsstandards.",
      },
      {
        question: "Wie erhalte ich die 10€ Freebet ohne Einzahlung?",
        answer:
          "Nach der Registrierung auf der Merkur Bets Webseite wird dir die 10€ Freiwette automatisch gutgeschrieben. Du musst keine Einzahlung tätigen.",
      },
      {
        question: "Muss ich die Wettsteuer bei Merkur Bets zahlen?",
        answer:
          "Nein, die gesetzliche Wettsteuer von 5% wird vollständig vom Anbieter übernommen. Deine Gewinne werden nicht durch die Steuer gemindert.",
      },
      {
        question: "Bietet Merkur Bets eine Mobile App an?",
        answer:
          "Ja, Merkur Bets bietet eine benutzerfreundliche App für iOS und Android an, die alle Funktionen der Desktop-Version beinhaltet. Mit der App kannst du jederzeit und überall bequem Wetten platzieren.",
      },
      {
        question: "Welche Zahlungsmethoden stehen zur Verfügung?",
        answer:
          "Du kannst Ein- und Auszahlungen über verschiedene Methoden tätigen, darunter Kreditkarten, E-Wallets wie PayPal, Banküberweisungen und Prepaid-Karten. Alle Transaktionen sind sicher und schnell abgewickelt, ohne zusätzliche Gebühren.",
      },
      {
        question: "Was ist der Bet Builder?",
        answer:
          "Der Bet Builder ist eine Funktion, mit der du verschiedene Wettmärkte innerhalb eines Spiels kombinieren kannst, um deine individuelle Wunschwette zu erstellen. Dies ermöglicht es dir, komplexe Wettstrategien zu verfolgen und maßgeschneiderte Wetten zu platzieren.",
      },
      {
        question: "Wie funktioniert der Kombi Boost?",
        answer:
          "Beim Kombi Boost erhältst du bis zu 30% zusätzlichen Gewinn auf deine Kombiwetten. Je mehr Auswahlen du kombinierst, desto höher fällt dein Bonus aus, was deine Gewinnchancen erheblich steigert.",
      },
      {
        question: "Gibt es eine Cash-Out-Funktion?",
        answer:
          "Ja, Merkur Bets bietet eine Cash-Out-Funktion an, mit der du Wetten vorzeitig auszahlen lassen kannst. Teilweiser oder automatischer Cash-Out sind jedoch nicht verfügbar, wodurch die Flexibilität etwas eingeschränkt ist.",
      },
    ],
    paymentMethods: [
      { name: "Visa", logo: visaLogo },
      { name: "Mastercard", logo: mastercardLogo },
      { name: "PayPal", logo: paypalLogo },
      { name: "Skrill", logo: skrillLogo },
      {
        name: "Paysafecard",
        logo: paysafecardLogo,
      },
      {
        name: "Sofortüberweisung",
        logo: sofortLogo,
      },
      { name: "Banküberweisung", logo: bankLogo },
    ],
    overview: {
      foundingYear: "2016",
      licence: "Deutsche Sportwetten-Lizenz gemäß Glücksspielstaatsvertrag",
      specialFeatures: [
        "10€ Freiwette ohne Einzahlung",
        "Wettsteuer wird vom Anbieter übernommen",
        "exklusive Features wie Quickbet und Bet Builder",
      ],
      website: "www.merkurbets.de",
      security: [
        "SSL-Verschlüsselung",
        "Maßnahmen zum verantwortungsbewussten Spielen",
      ],
    },
    pros: [
      "10€ Freebet ohne Einzahlung – starte sofort ohne eigenes Risiko",
      "Wettsteuer wird vom Anbieter übernommen – volle Gewinne ohne Abzüge",
      "100% 3er-Kombi Cashback bis zu 90€ und 10€ Gratiswette",
      "Innovative Features wie Quickbet, Bet Builder und Kombi Boost",
      "Breites Wettangebot mit vielen Sportarten und Spezialwetten",
      "Sichere und schnelle Ein- und Auszahlungen ohne Gebühren",
      "3er-Kombi-Versicherung bietet bis zu 90€ Cashback bei verlorenen Kombiwetten",
      "Benutzerfreundliche Mobile App für iOS und Android",
    ],
    cons: [
      "Kundensupport nicht rund um die Uhr erreichbar",
      "Keine teilweisen oder automatischen Cash-Outs verfügbar",
      "VIP-Services könnten ausgebaut werden",
      "Limitierte Zahlungsmethoden für Auszahlungen im Vergleich zu einigen Konkurrenten",
    ],
  },
};

export const onlineCasinoProvidersData = {
  betano: {
    name: "Betano",
    slug: "betano",
    title: "Betano Slots Erfahrungen & Test 2024 | Ist Betano seriös?",
    metaDescription:
      "Ausführlicher Betano Slots Test mit echten Erfahrungen ✓ 100% Einzahlungsbonus bis zu 100€ + 150 Freispiele ✓ Große Spielauswahl ✓ Jetzt informieren und profitieren!",
    ctaLink: "https://www.betano.com/de/",
    logo: betanoLogo,
    highlights: [
      "100% Bonus bis zu 100€ auf deine erste Einzahlung",
      "Bis zu 150 Freispiele für spannende Slot-Spiele",
      "Eine riesige Auswahl an Spielen, wie z.B. Play'n'Go & Novomatic",
      "Sichere und schnelle EIn- und Auszahlungen",
      "Top Casino-Erlebnis auf allen Geräten",
    ],
    bonus: {
      type: "100% Einzahlungsbonus + 150 Freispiele",
      maxBonus: "100€ + 150 Freispiele",
      minDeposit: "10€",
      maxDeposit: "100€",
      maxWager: "1€ pro Spin",
      wageringRequirements: "35x Bonus",
      timeframe: "30 Tage (ab Gutschrift)",
      validity: "1x pro Haushalt",
      availableIn: ["Deutschland"],
    },
    ratings: [
      { category: "Bonusangebote & Promotionen", score: 5 },
      { category: "Spielauswahl", score: 3 },
      { category: "Kundensupport", score: 5 },
      { category: "Treueprogramme", score: 5 },
      { category: "Auszahlungsbedingungen", score: 5 },
    ],
    faqs: [
      {
        question: "Ist Betano seriös und sicher?",
        answer:
          "Ja, Betano ist ein seriöses Casino mit einer Lizenz der Malta Gaming Authority und einer deutschen Lizenz gemäß dem Glücksspielstaatsvertrag. Der Anbieter setzt auf moderne Sicherheitsstandards und fördert verantwortungsvolles Spielen.",
      },
      {
        question: "Welche Boni bietet Betano für Neukunden?",
        answer:
          "Neukunden erhalten einen 100% Einzahlungsbonus bis zu 100€ sowie bis zu 150 Freispiele für ausgewählte Slot-Spiele. Diese Boni helfen dir, mit einem größeren Guthaben in die Casino-Welt einzusteigen.",
      },
      {
        question: "Welche Spiele bietet Betano an?",
        answer:
          "Betano bietet eine breite Palette an Spielen, darunter hunderte Slots von Anbietern wie Play’n GO, Novomatic und Gamomat, klassische Tischspiele wie Blackjack und Roulette sowie ein Live-Casino mit echten Dealern.",
      },
      {
        question: "Kann ich bei Betano auch mobil spielen?",
        answer:
          "Ja, Betano verfügt über eine benutzerfreundliche App für iOS und Android, die es dir ermöglicht, auch unterwegs problemlos zu spielen. Die mobile Webseite ist ebenfalls optimiert und bietet alle Funktionen der Desktop-Version.",
      },
      {
        question: "Wie erreiche ich den Betano Kundensupport?",
        answer:
          "Der Kundensupport ist über Live-Chat, E-Mail und Telefon erreichbar. Der Live-Chat ist die schnellste Möglichkeit, um Hilfe zu erhalten, während E-Mail und Telefon ebenfalls zur Verfügung stehen.",
      },
      {
        question: "Welche Zahlungsmethoden stehen bei Betano zur Verfügung?",
        answer:
          "Betano unterstützt eine Vielzahl von Zahlungsmethoden, darunter Visa, Mastercard, PayPal, Skrill, Paysafecard, Sofortüberweisung und Banküberweisung. Ein- und Auszahlungen sind in der Regel gebührenfrei und schnell bearbeitet.",
      },
      {
        question: "Gibt es bei Betano ein Treueprogramm?",
        answer:
          "Ja, Betano bietet ein umfangreiches Treueprogramm, in dem du für jede Spielrunde Punkte sammelst, die du gegen Boni, Gratiswetten oder andere Prämien eintauschen kannst. Zudem gibt es VIP-Services für besonders aktive Kunden mit exklusiven Vorteilen.",
      },
      {
        question: "Sind die Auszahlungen bei Betano schnell?",
        answer:
          "Ja, Auszahlungen werden in der Regel innerhalb von 24 bis 48 Stunden bearbeitet. Die Bearbeitungszeit kann je nach gewählter Zahlungsmethode variieren, ist jedoch insgesamt sehr zuverlässig und zügig.",
      },
    ],
    paymentMethods: [
      { name: "Visa", logo: visaLogo },
      { name: "Mastercard", logo: mastercardLogo },
      { name: "PayPal", logo: paypalLogo },
      { name: "Skrill", logo: skrillLogo },
      {
        name: "Paysafecard",
        logo: paysafecardLogo,
      },
      {
        name: "Sofortüberweisung",
        logo: sofortLogo,
      },
      { name: "Banküberweisung", logo: bankLogo },
    ],
    overview: {
      foundingYear: "2016",
      licence:
        "Malta Gaming Authority (MGA), Deutsche Lizenz gemäß Glücksspielstaatsvertrag",
      specialFeatures: [
        "Umfangreiches Slot-Angebot",
        "Exklusive Spiele",
        "Schnelle Auszahlungen",
        "Mobile App",
      ],
      website: "www.betano.com",
      security: ["SSL-Verschlüsselung", "Responsible Gaming Maßnahmen"],
      description:
        "Betano gehört zur Kaizen Gaming International Ltd., einem renommierten Unternehmen mit langjähriger Erfahrung in der iGaming-Branche. Der Anbieter legt großen Wert auf Transparenz und Spielerschutz, was sich in seinen umfangreichen Maßnahmen zur Förderung von verantwortungsbewusstem Spielen widerspiegelt.",
    },
    pros: [
      "Attraktiver Willkommensbonus: 100% Einzahlungsbonus bis zu 100€ + bis zu 150 Freispiele",
      "Große Spielauswahl: Hunderte Slots, Tischspiele und Live-Casino-Angebote von Top-Anbietern",
      "Benutzerfreundliche Mobile App: Verfügbar für iOS und Android",
      "Sichere und schnelle Ein- und Auszahlungen: Vielfältige Zahlungsmethoden ohne Gebühren",
      "Deutsche Lizenz: Höchste Sicherheitsstandards gemäß deutschem Glücksspielstaatsvertrag",
      "Exklusive Spiele: Bekannte Titel von Play’n GO, Novomatic und Gamomat",
      "Umfangreiches Treueprogramm: VIP-Programme und regelmäßige Promotionen für Bestandskunden",
    ],
    cons: [
      "Spielauswahl im Vergleich zu Konkurrenz begrenzt: Weniger Tischspiele und Live-Casino-Optionen als einige Mitbewerber",
      "Umsatzbedingungen beim Bonus: Hohe Umsatzanforderungen könnten abschreckend wirken",
      "Keine 24/7 Telefon-Hotline: Support ist nicht rund um die Uhr telefonisch erreichbar",
    ],
  },
  // merkur: {
  //   name: "Merkur",
  //   slug: "merkur",
  //   title: "Merkur Slots Erfahrungen & Test 2024 | Ist Merkur seriös?",
  //   metaDescription:
  //     "Umfassender Merkur Slots Test mit echten Erfahrungen ✓ 100% Einzahlungsbonus + 100 Freispiele ✓ Große Spielauswahl ✓ Jetzt informieren und profitieren!",
  //   ctaLink: "https://www.merkur.com/",
  //   logo: merkurLogo,
  //   highlights: [
  //     "50 Freispiele ohne Einzahlung – risikofreier Start",
  //     "100% Bonus bis zu 50€ auf deine erste Einzahlung",
  //     "50 zusätzliche Freispiele mit deiner ersten Einzahlung",
  //     "Eine große Auswahl an Merkur-Klassikern wie Book of Ra und El Toro",
  //     "Sichere und schnelle Ein- und Auszahlungen",
  //     "Top Casino-Erlebnis auf allen Geräten",
  //   ],
  //   bonus: {
  //     type: "100% Einzahlungsbonus + 100 Freispiele",
  //     maxBonus: "50€ + 100 Freispiele",
  //     minDeposit: "10€",
  //     maxDeposit: "50€",
  //     maxWager: "1€ pro Spin",
  //     wageringRequirements: "35x Bonus",
  //     timeframe: "30 Tage (ab Gutschrift)",
  //     validity: "1x pro Haushalt",
  //     availableIn: ["Deutschland"],
  //   },
  //   ratings: [
  //     { category: "Bonusangebote & Promotionen", score: 5 },
  //     { category: "Spielauswahl", score: 5 },
  //     { category: "Kundensupport", score: 4 },
  //     { category: "Treueprogramme", score: 4 },
  //     { category: "Auszahlungsbedingungen", score: 5 },
  //   ],
  //   faqs: [
  //     {
  //       question: "Ist Merkur Casino seriös und sicher?",
  //       answer:
  //         "Ja, Merkur Casino ist ein seriöser Anbieter. Der Betreiber verfügt über eine deutsche Lizenz und unterliegt den strengen Auflagen der deutschen Glücksspielbehörde. Zudem setzt Merkur auf moderne Sicherheitsstandards und verschlüsselt sämtliche Datenübertragungen.",
  //     },
  //     {
  //       question: "Gibt es einen Merkur Casino Bonus für Neukunden?",
  //       answer:
  //         "Ja, Merkur Casino bietet neuen Kunden einen attraktiven Willkommensbonus. Der Anbieter verdoppelt die erste Einzahlung bis zu einem Betrag von 50€ und schenkt zusätzlich 50 Freispiele für ausgewählte Slots.",
  //     },
  //     {
  //       question: "Welche Spiele bietet Merkur Casino an?",
  //       answer:
  //         "Merkur Casino überzeugt mit einer großen Auswahl an Spielen, darunter Slots, Tischspiele, Live-Casino und Jackpot-Slots. Die Spiele stammen von renommierten Anbietern wie Merkur, Play'n'GO, Pragmatic Play, Red Tiger und Novomatic.",
  //     },
  //     {
  //       question: "Kann ich bei Merkur Casino auch mobil spielen?",
  //       answer:
  //         "Ja, Merkur Casino verfügt über eine benutzerfreundliche App, die es Kunden ermöglicht, auch unterwegs zu spielen. Die App ist sowohl für iOS als auch für Android verfügbar und bietet alle Funktionen der Desktop-Version.",
  //     },
  //     {
  //       question: "Wie erreiche ich den Merkur Casino Kundensupport?",
  //       answer:
  //         "Der Merkur Casino Kundensupport steht Kunden per Live-Chat und E-Mail zur Verfügung. Der Live-Chat ist die schnellste und einfachste Möglichkeit, um Hilfe zu erhalten. Alternativ können Kunden auch eine E-Mail schreiben.",
  //     },
  //   ],
  //   paymentMethods: [
  //     { name: "Visa", logo: visaLogo },
  //     { name: "Mastercard", logo: mastercardLogo },
  //     { name: "PayPal", logo: paypalLogo },
  //     { name: "Skrill", logo: skrillLogo },
  //     {
  //       name: "Paysafecard",
  //       logo: paysafecardLogo,
  //     },
  //     {
  //       name: "Sofortüberweisung",
  //       logo: sofortLogo,
  //     },
  //     { name: "Banküberweisung", logo: bankLogo },
  //   ],
  //   overview: {
  //     foundingYear: "2001",
  //     licence:
  //       "Gibraltar Gambling Commission, deutsche Lizenz gemäß dem neuen Glücksspielstaatsvertrag",
  //     specialFeatures: [
  //       "Beliebte Merkur-Klassiker und exklusive Spiele",
  //       "Innovative Funktionen wie Turbo Spins und Autoplay",
  //       "Cash-Out-Funktion",
  //       "Mobile App für iOS und Android",
  //     ],
  //     website: "www.merkur.com",
  //     security: ["SSL-Verschlüsselung", "Responsible Gaming Maßnahmen"],
  //     shortDescription:
  //       "Merkur gehört zur Gauselmann Gruppe, einem etablierten Unternehmen in der Glücksspielbranche mit langjähriger Erfahrung. Der Anbieter legt großen Wert auf Transparenz und Spielerschutz, was sich in seinen umfangreichen Maßnahmen zur Förderung von verantwortungsbewusstem Spielen widerspiegelt.",
  //     description:
  //       "Merkur ist seit 2001 im Markt und gehört zur renommierten Gauselmann Gruppe, einem etablierten Unternehmen in der Glücksspielbranche mit langjähriger Erfahrung. Das Casino verfügt über eine deutsche Lizenz gemäß dem neuen Glücksspielstaatsvertrag sowie eine Lizenz der Gibraltar Gambling Commission. Mit einer Vielzahl an Spielen, sicheren Zahlungsmethoden und einem starken Fokus auf Spielerschutz bietet Merkur ein zuverlässiges und unterhaltsames Casino-Erlebnis.",
  //   },
  //   pros: [
  //     "50 Freispiele ohne Einzahlung – risikofreier Einstieg",
  //     "Attraktiver Willkommensbonus mit 100% bis zu 50€ und 50 Freispiele",
  //     "Breite Spielauswahl mit beliebten Merkur-Klassikern",
  //     "Benutzerfreundliche Mobile App für iOS und Android",
  //     "Sichere und schnelle Auszahlungen ohne Gebühren",
  //     "Innovative Funktionen wie Turbo Spins und Cash-Out",
  //     "Umfangreiches Treueprogramm für Stammkunden",
  //   ],
  //   cons: [
  //     "Telefonischer Kundensupport nicht verfügbar",
  //     "Bonusbedingungen könnten für einige Spieler hoch erscheinen",
  //     "Auswahl bei Auszahlungsmethoden könnte größer sein",
  //   ],
  // },
  // happybet: {
  //   name: "Happybet",
  //   slug: "happybet",
  //   title: "Happybet Slots Erfahrungen & Test 2024 | Ist Happybet seriös?",
  //   metaDescription:
  //     "Umfassender Happybet Slots Test mit echten Erfahrungen ✓ Keine aktuellen Bonusangebote ✓ Große Spielauswahl ✓ Jetzt informieren und profitieren!",
  //   ctaLink: "https://www.hpybet.com/",
  //   logo: happybetLogo,
  //   highlights: [
  //     "Große Auswahl an Slots von Top-Anbietern wie NetEnt und Play'n GO",
  //     "Beliebte Klassiker wie Book of Ra und Lord of the Ocean",
  //     "Moderne und aufregende Slot-Spiele ohne erforderliche Boni",
  //     "Sichere und schnelle Ein- und Auszahlungen",
  //     "Benutzerfreundliche Plattform auf allen Geräten",
  //   ],
  //   bonus: {
  //     type: "Aktuell kein Bonus",
  //     maxBonus: "Aktuell kein Bonus",
  //     minDeposit: "Aktuell kein Bonus",
  //     wageringRequirements: "Aktuell kein Bonus",
  //     maxDeposit: "Aktuell kein Bonus",
  //     timeframe: "Aktuell kein Bonus",
  //     validity: "Aktuell kein Bonus",
  //     availableIn: ["Deutschland"],
  //   },
  //   ratings: [
  //     { category: "Bonusangebote & Promotionen", score: 0 },
  //     { category: "Spielauswahl", score: 5 },
  //     { category: "Kundensupport", score: 4 },
  //     { category: "Treueprogramme", score: 5 },
  //     { category: "Auszahlungsbedingungen", score: 5 },
  //   ],
  //   faqs: [
  //     {
  //       question: "Ist Happybet Casino seriös und sicher?",
  //       answer:
  //         "Ja, Happybet Casino ist ein seriöser Anbieter. Der Betreiber verfügt über eine deutsche Lizenz und unterliegt den strengen Auflagen der deutschen Glücksspielbehörde. Zudem setzt Happybet auf moderne Sicherheitsstandards und verschlüsselt sämtliche Datenübertragungen.",
  //     },
  //     {
  //       question: "Gibt es einen Happybet Casino Bonus für Neukunden?",
  //       answer:
  //         "Aktuell bietet Happybet keinen speziellen Willkommensbonus für Neukunden an. Dennoch überzeugt das Casino durch seine umfangreiche Spielauswahl und benutzerfreundliche Plattform.",
  //     },
  //     {
  //       question: "Welche Spiele bietet Happybet Casino an?",
  //       answer:
  //         "Happybet Casino bietet eine große Auswahl an Spielen, darunter Slots, Tischspiele und Live-Casino-Angebote. Die Spiele stammen von renommierten Anbietern wie NetEnt, Play'n GO, Pragmatic Play, Red Tiger und Novomatic, was für hohe Qualität und Vielfalt sorgt.",
  //     },
  //     {
  //       question: "Kann ich bei Happybet Casino auch mobil spielen?",
  //       answer:
  //         "Ja, Happybet Casino verfügt über eine benutzerfreundliche mobile Plattform, die es Kunden ermöglicht, auch unterwegs zu spielen. Die mobile Version ist sowohl für iOS als auch für Android optimiert und bietet alle Funktionen der Desktop-Version.",
  //     },
  //     {
  //       question: "Wie erreiche ich den Happybet Casino Kundensupport?",
  //       answer:
  //         "Der Happybet Casino Kundensupport steht Kunden per Live-Chat und E-Mail zur Verfügung. Der Live-Chat ist die schnellste und einfachste Möglichkeit, um Hilfe zu erhalten. Alternativ können Kunden auch eine E-Mail schreiben.",
  //     },
  //   ],
  //   paymentMethods: [
  //     { name: "Visa", logo: visaLogo },
  //     { name: "Mastercard", logo: mastercardLogo },
  //     { name: "PayPal", logo: paypalLogo },
  //     { name: "Skrill", logo: skrillLogo },
  //     {
  //       name: "Paysafecard",
  //       logo: paysafecardLogo,
  //     },
  //     {
  //       name: "Sofortüberweisung",
  //       logo: sofortLogo,
  //     },
  //     { name: "Banküberweisung", logo: bankLogo },
  //   ],
  //   overview: {
  //     foundingYear: "2018",
  //     licence:
  //       "Malta Gaming Authority (MGA), deutsche Lizenz gemäß dem neuen Glücksspielstaatsvertrag",
  //     specialFeatures: [
  //       "Große Auswahl an Slots von Top-Anbietern",
  //       "Benutzerfreundliche mobile Plattform",
  //       "Sichere und schnelle Auszahlungen",
  //       "Umfangreiches Treueprogramm für Stammkunden",
  //     ],
  //     website: "www.happybet.com",
  //     security: ["SSL-Verschlüsselung", "Responsible Gaming Maßnahmen"],
  //     shortDescription:
  //       "Happybet ist ein etabliertes Unternehmen in der Online-Casino-Branche, das seit seiner Gründung großen Wert auf Spielerschutz und Transparenz legt. Mit einer Lizenz der Malta Gaming Authority und der deutschen Glücksspielbehörde bietet Happybet ein sicheres und vertrauenswürdiges Casino-Erlebnis. Die umfangreiche Spielauswahl, kombiniert mit schnellen Auszahlungen und einem zuverlässigen Kundensupport, macht Happybet zu einer attraktiven Wahl für Slot-Enthusiasten.",
  //       description: "Happybet ist seit 2018 eine feste Größe auf dem deutschen Online-Casino-Markt und gehört zur renommierten Happybet Gruppe, einem etablierten Unternehmen mit langjähriger Erfahrung in der Glücksspielbranche. Das Casino verfügt über eine deutsche Lizenz gemäß dem neuen Glücksspielstaatsvertrag sowie eine Lizenz der Malta Gaming Authority (MGA), was höchste Sicherheitsstandards und fairen Spielbetrieb garantiert. Mit einer beeindruckenden Vielfalt an Spielen, sicheren Zahlungsmethoden und einem starken Fokus auf Spielerschutz bietet Happybet ein zuverlässiges und unterhaltsames Casino-Erlebnis für Spieler aller Erfahrungsstufen.",
  //   },
  //   pros: [
  //     "Große Auswahl an hochwertigen Slots von Top-Anbietern",
  //     "Beliebte Klassiker wie Book of Ra und Lord of the Ocean",
  //     "Moderne und aufregende Slot-Spiele ohne notwendige Boni",
  //     "Sichere und schnelle Ein- und Auszahlungen",
  //     "Benutzerfreundliche Plattform auf allen Geräten",
  //     "Umfangreiches Treueprogramm für Stammkunden",
  //     "Zuverlässiger Kundensupport via Live-Chat und E-Mail",
  //   ],
  //   cons: [
  //     "Aktuell keine Bonusangebote verfügbar",
  //     "Telefonischer Kundensupport nicht verfügbar",
  //     "Auswahl bei Auszahlungsmethoden könnte größer sein",
  //   ],
  // },
};
